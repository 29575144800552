<template>
  <div role="list">
    <!-- Documents loop -->
    <div
      v-for="idx in 3"
      :key="idx"
      class="flex h-[50px] items-center"
      :class="{
        'triangle text-primary rounded-md bg-gray-700': idx === 1,
      }"
    >
      <!-- Type -->
      <div class="w-[126px] p-4">
        <Skeleton class="w-full" :height="20" />
      </div>

      <!-- Filename -->
      <div class="flex-grow p-4">
        <Skeleton :width="400" :height="20" />
      </div>

      <!-- Created At -->
      <div class="flex-shrink p-4">
        <Skeleton :width="80" :height="20" />
      </div>

      <div class="w-12 p-4" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.triangle {
  position: relative;
  &::before {
    @apply absolute -right-2 h-0 w-0 border-solid content-[''];
    border-width: 20px 0 20px 12px;
    border-color: transparent transparent transparent #4d4d4d;
  }
}
</style>
